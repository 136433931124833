<template>
  <vuestic-widget>
    <data-tables-server
      :data="checklistRespostas"
      style="width: 100%"
      @row-dblclick="pushChecklistPerguntaResposta"
    >
      <el-table-column
        prop="checklist_nome"
        label="Checklist"
        width="width"
        sortable
      />
      <el-table-column
        prop="situacao"
        label="Situação"
        sortable="custom"
        width="width"
      >
        <template
          slot-scope="row"
          style="width: 100%;"
        >
          <div
            :class="cores[row.row.situacao]"
            class="badge"
          >{{situacao[row.row.situacao]}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="data"
        label="Data"
        width="width"
        sortable
      >
        <template
          slot-scope="row"
          style="width: 100%;"
        >
          <div>{{row.row.data | dateBr}}</div>
        </template>
      </el-table-column>
    </data-tables-server>
  </vuestic-widget>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';


export default {
  filters: {
    dateBr(value) {
      if (!value) return '';
      return moment(value).format(
        'DD/MM/YYYY',
      );
    },
  },
  data() {
    return {
      checklistRespostas: [],
      checklistID: null,
      me: null,
      collapse: false,
      loading: true,
      checklists: [],
      count: null,
      idEmpresa: null,
      acessos: [],
      acesso: { id: 0 },
      funcionarios: [],
      situacaos: {
        1: 'No prazo',
        2: 'Atrasado',
        3: 'Concluído',
        4: 'Cancelado',
        5: 'Esperando Confirmação',
      },
      situacao: ['', 'No prazo', 'Atrasado', 'Concluído', 'Cancelado', 'Esperando Confirmação'],

      cores: ['', 'badge-info', 'badge-danger', 'badge-success', 'badge-dark', 'badge-warning'],
    };
  },
  created() {
    const user = JSON.parse(localStorage.user);
    this.checklistID = Number(this.$router.history.current.params.checklist);
    this.getChecklistResposta();
    this.me = user.funcionario.id;
    this.idEmpresa = Number(localStorage.empresaSelecionada);
    this.getFuncionario();
    this.getAllAcessos();
  },
  methods: {
    ...mapActions('checklistResposta', ['getChecklistRespostaBySituation']),
    ...mapActions('acesso', [
      'getAcessos',

    ]),
    ...mapActions('funcionario', [
      'getFuncionariosList',

    ]),
    openModalAcesso() {
      this.$refs.acesso.open();
    },
    async getFuncionario() {
      try {
        const res = await this.getFuncionariosList();
        this.funcionarios = res.filter(f => f.empresas.includes(Number(this.idEmpresa)));
      } catch (error) {
        console.error(error);
      }
    },
    async getAllAcessos() {
      try {
        this.acessos = await this.getAcessos();
        this.getAcessoByChecklist();
      } catch (error) {
        console.error(error);
      }
    },
    acessoSaved() {
      this.getAcessoByChecklist();
      this.message('success', 'Acesso Salvo com sucesso');
    },
    acessoError() {
      this.message('error', 'Algo deu errado');
    },
    getAcessoByChecklist() {
      const acesso = this.acessos
        .find(
          a => a.checklist === this.checklistID
            && a.empresa === this.idEmpresa,
        );
      if (acesso) {
        this.acesso = acesso;
      } else {
        this.acesso = {
          empresa: this.idEmpresa,
          checklist: this.checklistID,
          funcionario: [],
        };
      }
    },
    async getChecklistResposta() {
      try {
        this.checklistRespostas = await this.getChecklistRespostaBySituation({ minhas: '', checklist: this.checklistID });
      } catch (error) {
        console.error(error);
      }
    },
    pushChecklistPerguntaResposta({ id }) {
      this.$router.push({ name: 'lista-checklist-resposta-pergunta-user-supervisor', params: { checklist: this.checklistID, resposta: id } });
    },
  },
};
</script>

<style>
</style>
